import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import Checkbox from '@salesforce/design-system-react/lib/components/checkbox';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Input from '@salesforce/design-system-react/lib/components/input';
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';

const undeliveredOptions = [
    {
        id: 'Undelivered Due To Client',
        label: 'Undelivered Due To Client',
    },
    {
        id: 'OSG Cannot Deliver',
        label: 'OSG Cannot Deliver'
    }
];

export default class CloseSAModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            data : [],
            noCheckedAttendees : true,
            disabled : true,
            cardDetailId : this.props.cardDetailId,
            closedSA : this.props.record[0].Status === "Completed" ? true : false,
            isLoading : false,
            numberOfToyotaQuestionnaires : 0,
            numberOfEvaluations : 0,
            successfullyDeliveredSA : true,
            message : this.props.record[0].Status === "Completed" ? 'This Service Appointment has been successfully completed!' : '',
            undeliveredReasonSelection : [],
            undeliveredReasonSelectionValue : '',
            undeliveredReasonErrorMessage : '',
            undeliveredReasonDetails : ''
        }

        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.processDisableSubmission = this.processDisableSubmission.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.validateInformation = this.validateInformation.bind(this);
    }

    componentDidMount(){
        this.showSpinner();
        var numberOfToyotaQuestionnaires = 0;
        var numberOfEvaluations = 0;

        APIWorker.getServiceAppointment(this.state.cardDetailId)
        .then(res => {
            console.log(res.data);
            numberOfToyotaQuestionnaires = res.data[0].Number_of_Toyota_Questionnaires__c;
        })
        .then(res => {
            APIWorker.getServiceAppointmentEvalutations(this.state.cardDetailId)
            .then(res => {
                console.log(res.data);
                numberOfEvaluations = res.data.length;
            })
            .then(res => {
                APIWorker.getCheckedAttendees(this.state.cardDetailId)
                .then(res => {
                    this.setState({
                        data : res.data,
                        noCheckedAttendees : res.data.length === 0 ? true : false,
                        numberOfToyotaQuestionnaires,
                        numberOfEvaluations
                    },
                        () => {
                            this.hideSpinner();
                            this.processDisableSubmission();
                        }
                    )
                })
                .catch(error => {
                    this.showErrorAlert();
                    this.setState({
                        disabled : true,
                        message : 'There was an error. Please try again later.'
                    });
                });
            })
            .catch(error => {
                this.showErrorAlert();
                this.setState({
                    disabled : true,
                    message : 'There was an error. Please try again later.'
                });
            });  
        })
        .catch(error => {
            this.showErrorAlert();
            this.setState({
                disabled : true,
                message : 'There was an error. Please try again later.'
            });
        });        
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    validateInformation(){
        this.clearErrors();
        this.showSpinner();

        if(this.state.successfullyDeliveredSA === false && this.state.undeliveredReasonSelectionValue === ''){
            this.setState({
                undeliveredReasonErrorMessage : 'Please select undelivered reason.'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.successfullyDeliveredSA === false && this.state.undeliveredReasonDetails === ''){
            this.setState({
                undeliveredReasonErrorMessage : 'Please provide additional details.'
            });
            this.hideSpinner();
            return;
        }

        this.closeSA();
    }

    clearErrors(){
        this.setState({
            undeliveredReasonErrorMessage : ''
        })
    }

    processDisableSubmission(){
        let appointmentNumber = this.props.record[0].AppointmentNumber;
        let product = this.props.record[0].Product_Name__c;
        let productFamily = this.props.record[0].Product_Family__c;
        let accountName = this.props.record[0].Account__r.Name;
        let osgAssignedResourceName = this.props.record[0].OSG_Assigned_Resources__r[0].ServiceResource__r.Name;
        let contractor = this.props.record[0].Contractor__c ? this.props.record[0].Contractor__c : '';
        let needsResult = false;
        let message = 'REMINDER: Ensure any late attendees have been checked in / all evalutions have been submitted.';

        if((product.includes("JHSC") || product.includes("WAH")) && !product.includes("JHSCII") && !product.includes("JHSCR") && !product.includes('JHSCI1') && !product.includes('JHSCI2')){
            needsResult = true;
        }
        
        //Check all requirements to close course and set boolean to false if any requirements not met, otherwise defaults to true
        let passedTest = true;

        if(!this.state.successfullyDeliveredSA){
            message = '';
        }
        else {
            if(this.state.noCheckedAttendees && ((productFamily === 'Courses' && !product.includes("RFT")) || (productFamily === 'Evaluations' && !accountName.includes('Toyota Motor')))){
                message = 'Service Appointment cannot be closed until attendees have been checked in / evaluations have been entered.'
                passedTest = false;
            }
            else if(this.state.numberOfEvaluations === 0 && productFamily === 'Evaluations' && !accountName.includes('Toyota Motor')){
                message = 'Service Appointment cannot be closed until attendees have an evaluation entered.'
                passedTest = false;
            }
            else if(accountName.includes('Toyota Motor') && this.state.numberOfToyotaQuestionnaires < 1){
                message = 'Service Appointments for Toyota require a questionnaire to be completed prior to closing SA!'
                passedTest = false;
            }
            else if(osgAssignedResourceName === 'OSG Contractors' && contractor === ''){
                message = 'Service Appointments assigned to OSG Contractor needs a specific contractor set prior to delivering SA! Contact <a href="mailto:trainingadmin@osg.ca?' + encodeURI('subject=Set Contractor on ' + appointmentNumber) + '">trainingadmin@osg.ca or training coordinators</a> to resolve.'
                passedTest = false;
            }
            else if(!this.state.noCheckedAttendees){
                this.state.data.map((attendee) => {
                    if(attendee.Theory_Status__c !== 'Passed' && attendee.Theory_Status__c !== 'Failed' && productFamily === 'Courses'){
                        message = 'Service Appointment cannot be closed until all attendees have a theory status set.'
                        passedTest = false;
                    }
                });
            }
            else if(needsResult){
                this.state.data.map((attendee) => {
                    if(attendee.Theory_Exam_Results__c === null || attendee.Theory_Status__c === 'Pending'){
                        message = 'Service Appointment cannot be closed until attendees for JHSC/WAH have their exam status and results entered in.'
                        passedTest = false;
                    }
                });
            }
        }


        if(passedTest){
            this.setState({
                disabled : false,
                message
            })
        }
        else{
            this.setState({
                disabled : true,
                message
            })
        }
    }

    closeSA(){
        this.showSpinner();
        let saStatus = 'Completed';

        if(this.state.undeliveredReasonSelectionValue !== ''){
            saStatus = this.state.undeliveredReasonSelectionValue;
        }

        let json = {
            "Status" : saStatus,
            "Reason_for_Cannot_Deliver__c" : this.state.undeliveredReasonDetails
        };

        APIWorker.updateServiceAppointment(this.props.cardDetailId, json)
        .then(res =>{
            this.setState({
                closedSA : true,
                disabled : true        
            },
                () => {
                    this.hideSpinner();
                    this.context.changeModal('');
                    this.context.showSuccessAlert('Service Appointment completed!');                
                }
            );
            this.context.changeFooter("CompletedServiceAppointment");
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }


    render(){
        return(
            <div>

                <Modal isOpen={true} heading="Complete Appointment" ariaHideApp={false} align="center" size="large"
                footer={[
                    <Button key="1" label="Cancel" onClick={() =>this.context.changeModal('')} />,
                    <Button key="2" disabled={this.state.disabled} label="Complete SA" onClick={() => this.validateInformation()} variant="brand"/>
                ]}>

                    <div className="slds-p-around_x-small">
                        <article className="slds-card">
                            <div className="slds-card__header slds-grid">
                                <div className="slds-media slds-media_center slds-has-flexi-truncate">
                                    <div className="slds-media__body">
                                        <Checkbox 
                                            id="successfullyDeliveredSA"
                                            key="successfullyDeliveredSA"
                                            labels={{
                                                label: 'Delivery of SA',
                                                toggleDisabled: 'Not able to deliver',
                                                toggleEnabled: 'Delivered successfully!'
                                            }}
                                            checked={this.state.successfullyDeliveredSA}
                                            variant="toggle"
                                            required
                                            onChange={() => {
                                                this.setState({
                                                    successfullyDeliveredSA : !this.state.successfullyDeliveredSA,
                                                    undeliveredReasonSelection : [],
                                                    undeliveredReasonSelectionValue : '',
                                                    undeliveredReasonDetails : '',
                                                    undeliveredReasonErrorMessage : ''
                                                },
                                                () => {
                                                    this.processDisableSubmission();
                                                }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="slds-media__body">
                                        <div dangerouslySetInnerHTML={{__html:this.state.message}}></div>

                                        {
                                            !this.state.successfullyDeliveredSA && 
                                            <>
                                                <Combobox 
                                                    labels={{
                                                        label: 'Reason for not delivering',
                                                        placeholder: 'Select reason'
                                                    }}
                                                    events={{
                                                        onSelect: (event, data) => {
                                                            if(data.selection.length > 0){
                                                                this.setState({
                                                                    undeliveredReasonSelection : [{id:data.selection[0].id, label:data.selection[0].id}],
                                                                    undeliveredReasonSelectionValue : data.selection[0].id
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    options={undeliveredOptions}
                                                    selection={this.state.undeliveredReasonSelection}
                                                    value={this.state.undeliveredReasonSelectionValue}
                                                    required={!this.state.successfullyDeliveredSA}
                                                    variant="readonly"
                                                />

                                                <Input 
                                                    label="Provide details for why it could not be delivered."
                                                    placeholder="ex. Equipment unavailable, No time to deliver, etc"
                                                    type="text"
                                                    errorText={this.state.undeliveredReasonErrorMessage}
                                                    onChange={(event, data) => {
                                                        this.setState({
                                                            undeliveredReasonDetails : data.value
                                                        });
                                                    }}
                                                    required={!this.state.successfullyDeliveredSA}
                                                />

                                                {
                                                    this.state.undeliveredReasonSelectionValue === 'Undelivered Due To Client' &&
                                                    <div>
                                                        <br/>
                                                        <b style={{'color':'red'}}>NOTE: Client will be billed for service!</b>
                                                    </div>
                                                }

                                                {
                                                    this.state.undeliveredReasonSelectionValue === 'OSG Cannot Deliver' &&
                                                    <div>
                                                        <br/>
                                                        <b>Used for when service could not be completed outside of clients control. Can cover (but not limited to), Vehicle breakdown, Trainer absence, Inclement weather, Ran out of time. </b>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </Modal>
            </div>
        )
    }
}